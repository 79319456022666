<template>
	<div v-loading="loading" element-loading-text="下载中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row class="margin-bottom-sm">
			<el-card>
				<el-form ref="formSearch" :model="searchData" label-width="80px" size="small" :inline="true"
					:rules="tableRules">
					<el-form-item prop="date_range" label="当前年份">
						<el-date-picker v-model="searchData.date_range" type="year" align="right" value-format="yyyy"
							placeholder="请选择年份" />
					</el-form-item>
					<el-form-item prop="date_range_compare" label="对比年份">
						<el-date-picker v-model="searchData.date_range_compare" type="years" value-format="yyyy"
							placeholder="选择一个或多个年份">
						</el-date-picker>
					</el-form-item>

					<el-form-item prop="area">
						<el-select v-model="searchData.area" placeholder="请选择地区" multiple collapse-tags>
							<el-option v-for="item in areaOptions" :key="item.index" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item prop="brand">
						<el-select v-model="searchData.brand" placeholder="请选择品牌">
							<el-option v-for="item in brandOptions" :key="item.index" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item prop="products">
						<el-cascader filterable v-model="searchData.products" :options="productOptions"
							:props="{ multiple: true,  expandTrigger: 'hover',}" collapse-tags clearable
							placeholder="请选择产品">
						</el-cascader>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitFormSearchData('formSearch')">查看报表</el-button>
						<el-button @click="resetSearchForm('formSearch')">重置</el-button>
						<el-button type="success" @click="submitExportData('formSearch')">导出</el-button>
					</el-form-item>
					<el-divider direction="vertical"></el-divider>
					<el-form-item>
						<el-checkbox v-model="quantity_checked">销量</el-checkbox>
						<el-checkbox v-model="amount_checked">订货额</el-checkbox>
					</el-form-item>
					<el-divider direction="vertical"></el-divider>
					<el-form-item label="图表风格">
						<el-select v-model="echartTheme">
							<el-option v-for="theme in themes" :key="theme.value" :label="theme.label"
								:value="theme.value"></el-option>
						</el-select>
					</el-form-item>
				</el-form>

			</el-card>
		</el-row>

		<el-row style="margin-top:10px;">
			<el-card>
				<el-tabs v-model="tableActiveName">
					<el-tab-pane label="按品类" name="table_category">
						<el-table :data="tableDataCategory" style="width: 100%; " show-summary
							:summary-method="getSummaries" max-height="600">
							<el-table-column label="品类" prop="category" sortable fixed width="100" />
							<el-table-column label="品牌" prop="brand" sortable fixed width="110" />
							<el-table-column :label="tableTitle_category">
								<el-table-column :label="tableTitle_this_year">
									<el-table-column v-if="quantity_checked" label="销量(桶)" prop="current_sales_quantity"
										sortable width="100" />
									<el-table-column v-if="amount_checked" label="订货额(元)" prop="current_sales_amount"
										sortable width="120" />
								</el-table-column>

								<el-table-column :label="item.label" v-for="(item,index) in tableHeader"
									:key="item.label">
									<el-table-column v-if="quantity_checked" label="销量(桶)" :prop="item.prop_quantity"
										sortable width="100"></el-table-column>
									<el-table-column v-if="quantity_checked" label="销量增长(桶)" sortable
										:prop="item.prop_increase_quantity" width="130"></el-table-column>
									<el-table-column v-if="quantity_checked" label="销量增长率(%)"
										:prop="item.prop_rate_quantity" width="120"></el-table-column>
									<el-table-column v-if="amount_checked" label="订货额(元)" :prop="item.prop_amount"
										sortable width="120"></el-table-column>
									<el-table-column v-if="amount_checked" label="订货额增长(元)" sortable
										:prop="item.prop_increase_amount" width="140"></el-table-column>
									<el-table-column v-if="amount_checked" label="订货额增长率(%)"
										:prop="item.prop_rate_amount" width="140"></el-table-column>
								</el-table-column>
							</el-table-column>
						</el-table>
					</el-tab-pane>

					<el-tab-pane label="按单品" name="table_product">
						<el-table :data="tableDataProduct" style="width: 100%; " show-summary
							:summary-method="getSummaries" max-height="600">
							<el-table-column label="产品" prop="product__name" sortable fixed width="180" />
							<el-table-column label="品牌" prop="brand" sortable fixed width="110" />
							<el-table-column :label="table_title_product">
								<el-table-column :label="tableTitle_this_year">
									<el-table-column v-if="quantity_checked" label="销量(桶)" prop="current_sales_quantity"
										sortable width="100" />
									<el-table-column v-if="amount_checked" label="订货额(元)" prop="current_sales_amount"
										sortable width="120" />
								</el-table-column>

								<el-table-column :label="item.label" v-for="(item,index) in tableHeader"
									:key="item.label">
									<el-table-column v-if="quantity_checked" label="销量(桶)" :prop="item.prop_quantity"
										sortable width="100"></el-table-column>
									<el-table-column v-if="quantity_checked" label="销量增长(桶)" sortable
										:prop="item.prop_increase_quantity" width="130"></el-table-column>
									<el-table-column v-if="quantity_checked" label="销量增长率(%)"
										:prop="item.prop_rate_quantity" width="120"></el-table-column>
									<el-table-column v-if="amount_checked" label="订货额(元)" :prop="item.prop_amount"
										sortable width="120"></el-table-column>
									<el-table-column v-if="amount_checked" label="订货额增长(元)" sortable
										:prop="item.prop_increase_amount" width="140"></el-table-column>
									<el-table-column v-if="amount_checked" label="订货额增长率(%)"
										:prop="item.prop_rate_amount" width="140"></el-table-column>
								</el-table-column>
							</el-table-column>
						</el-table>
					</el-tab-pane>
				</el-tabs>

			</el-card>
		</el-row>

		<el-row style="margin-top: 10px;" :gutter="15">
			<el-col :sm="24" :xs="24">
				<el-card>
					<el-tabs v-model="barChartActiveName">
						<el-tab-pane label="按品类" name="bar_quantity">
							<div class="plate-card"><v-chart :options="bar_chart_category_quantity" autoresize
									:theme="echartTheme">/></v-chart>
							</div>
							<div class="plate-card"><v-chart :options="bar_chart_category_amount" autoresize
									:theme="echartTheme">/></v-chart>
							</div>
						</el-tab-pane>

						<el-tab-pane label="按单品" name="bar_amount">
							<div class="plate-card"><v-chart :options="bar_chart_product_quantity" autoresize
									:theme="echartTheme">/></v-chart>
							</div>
							<div class="plate-card"><v-chart :options="bar_chart_product_amount" autoresize
									:theme="echartTheme">/></v-chart>
							</div>
						</el-tab-pane>
					</el-tabs>
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>


<script>
	import ECharts from 'vue-echarts';
	export default {
		inject: ['logout'],
		data() {
			return {
				searchData: {
					area: '',
					date_range: '',
					date_range_compare: '',
					brand: '',
					products: ''
				},
				tableActiveName: 'table_category',
				quantity_checked: true,
				amount_checked: true,
				tableDataCategory: [],
				tableDataProduct: [],
				tableSummary: [],
				tableTitle_category: '产品销量订货额对比 - 按品类',
				table_title_product: '产品销量订货额对比 - 按单品',
				tableTitle_this_year: '当前年份',
				tableHeader: [],
				tableRules: {
					date_range: [{
						required: true,
						message: '请选择当前年份',
						trigger: 'change'
					}],
					date_range_compare: [{
						required: true,
						message: '请选择对比年份',
						trigger: 'change'
					}]
				},
				loading: false,
				echartTheme: 'light',
				themes: [{
						value: 'light',
						label: '默认'
					},
					{
						value: 'dark',
						label: '黑暗'
					},
					{
						value: 'shine',
						label: '闪亮'
					},
					{
						value: 'vintage',
						label: '复古'
					},
					{
						value: 'helianthus',
						label: '太阳花'
					},
					{
						value: 'tech-blue',
						label: '科技蓝'
					},
					{
						value: 'london',
						label: '伦敦'
					},
					{
						value: 'roma',
						label: '罗马'
					},
					{
						value: 'sakura',
						label: '樱花粉'
					},
					{
						value: 'fresh-cut',
						label: '鲜果切'
					}
					// 你可以继续添加其他主题
				],
				barChartActiveName: 'bar_quantity',
				areaOptions: [],
				brandOptions: [{
					value: 'all',
					label: '国产+进口'
				}, {
					value: '进口鲁沃夫',
					label: '进口鲁沃夫'
				}, {
					value: '国产鲁沃夫',
					label: '国产鲁沃夫'
				}],
				productOptions: [],
				bar_chart_category_quantity: {},
				bar_chart_category_amount: {},
				bar_chart_product_quantity: {},
				bar_chart_product_amount: {},
				bar_chart_quantity: {
					title: {
						text: '产品销量(桶)',
						left: 'center',
						top: 20
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						},
						formatter: function(params) {
							let tooltipText = `${params[0].axisValue}<br/>`;
							let firstValue = params[0].value;
							params.forEach((item, index) => {
								const formattedValue = item.value.toLocaleString();
								const diffValue = firstValue - item.value;
								if (index == 0) {
									tooltipText +=
										`${item.marker}${item.seriesName}：${formattedValue}（桶）<br/>`;
								} else {
									tooltipText +=
										`${item.marker}${item.seriesName}：${formattedValue}（桶）（差值：${diffValue.toLocaleString()} 桶）<br/>`;
								}

							});
							return tooltipText;
						}
					},
					toolbox: {
						show: true,
						orient: 'vertical',
						left: 'right',
						top: 'center',
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: true
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					legend: {
						bottom: 10,
						left: 'center',
						orient: 'horizontal',
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '10%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						data: []
					}],
					yAxis: [{
						type: 'value'
					}],
					series: [{
							name: '当前时段',
							type: 'bar',
							emphasis: {
								focus: 'series'
							},
							data: []
						},
						{
							name: '对比时段',
							type: 'bar',
							emphasis: {
								focus: 'series'
							},
							data: []
						},

					]
				},
				bar_chart_amount: {
					title: {
						text: '产品订货额（元）',
						left: 'center',
						top: 20
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						},
						formatter: function(params) {
							let tooltipText = `${params[0].axisValue}<br/>`;
							let firstValue = params[0].value;
							params.forEach((item, index) => {
								const formattedValue = item.value.toLocaleString();
								const diffValue = firstValue - item.value;
								if (index == 0) {
									tooltipText +=
										`${item.marker}${item.seriesName}：${formattedValue}（元）<br/>`;
								} else {
									tooltipText +=
										`${item.marker}${item.seriesName}：${formattedValue}（元）（差值：${diffValue.toLocaleString()} 元）<br/>`;
								}

							});
							return tooltipText;
						}
					},
					toolbox: {
						show: true,
						orient: 'vertical',
						left: 'right',
						top: 'center',
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: true
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					legend: {
						bottom: 10,
						left: 'center',
						orient: 'horizontal',
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '10%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						data: []
					}],
					yAxis: [{
						type: 'value'
					}],
					series: [{
							name: '当前时段',
							type: 'bar',
							emphasis: {
								focus: 'series'
							},
							data: []
						},
						{
							name: '对比时段',
							type: 'bar',
							emphasis: {
								focus: 'series'
							},
							data: []
						},

					]
				},
			};
		},
		mounted() {
			// 获取数据
			this.getAreaOptions();
			this.getProductOptions();
		},
		created() {

			// 深拷贝数据以取消双向绑定
			this.bar_chart_category_quantity = {
				...JSON.parse(JSON.stringify(this.bar_chart_quantity)), // 使用浅拷贝保留大部分属性
				tooltip: { // 手动添加 tooltip 以保留 formatter 函数
					...this.bar_chart_quantity.tooltip,
					formatter: this.bar_chart_quantity.tooltip.formatter
				}
			};

			this.bar_chart_category_amount = {
				...JSON.parse(JSON.stringify(this.bar_chart_amount)), // 使用浅拷贝保留大部分属性
				tooltip: { // 手动添加 tooltip 以保留 formatter 函数
					...this.bar_chart_amount.tooltip,
					formatter: this.bar_chart_amount.tooltip.formatter
				}
			};

			// 深拷贝数据以取消双向绑定
			this.bar_chart_product_quantity = {
				...JSON.parse(JSON.stringify(this.bar_chart_quantity)), // 使用浅拷贝保留大部分属性
				tooltip: { // 手动添加 tooltip 以保留 formatter 函数
					...this.bar_chart_quantity.tooltip,
					formatter: this.bar_chart_quantity.tooltip.formatter
				}
			};

			this.bar_chart_product_amount = {
				...JSON.parse(JSON.stringify(this.bar_chart_amount)), // 使用浅拷贝保留大部分属性
				tooltip: { // 手动添加 tooltip 以保留 formatter 函数
					...this.bar_chart_amount.tooltip,
					formatter: this.bar_chart_amount.tooltip.formatter
				}
			};

		},
		methods: {
			//重置搜索表单
			resetSearchForm(formName) {
				let that = this;
				this.$refs[formName].resetFields();
				this.$refs[formName].clearValidate();
				this.searchData.date_range_compare = '';
				this.searchData.date_range = '';
			},
			//提交搜索
			submitFormSearchData(formName) {
				// console.log(this.searchData)
				let that = this;
				this.$refs[formName].validate((valid) => {
					if (valid) {
						that.getTableAndChartData(that.searchData);

					} else {
						return false;
					}
				});
			},
			// 获取地区列表
			getAreaOptions() {
				let that = this;
				this.$axios
					.get('/apis/get_area_list/')
					.then(function(response) {
						if (response.data.code == '401') {
							that.logout();
						} else {
							that.areaOptions = response.data;
						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			// 获取产品列表
			getProductOptions() {
				let that = this;
				this.$axios
					.get('/apis/get_product_category_list/')
					.then(function(response) {
						that.productOptions = response.data;
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			//	获取总计数据			
			getSummaries() {
				return this.tableSummary;
			},
			//获取表格及图表数据
			getTableAndChartData(queryData) {
				let that = this;
				that.loading = true;
				//添加表单数据
				let fd = new FormData();
				let formData = queryData;
				for (let key in formData) {
					fd.append(key, formData[key]);
				}
				this.$axios({
						url: '/apis/get_yearly_product_compare/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then(function(res) {
						// console.log(res)
						that.loading = false;
						if (res.data.code == '401') {
							that.logout();
						} else {
							that.tableDataCategory = res.data.tableDataCategory;
							that.tableDataProduct = res.data.tableDataProduct;
							that.tableHeader = res.data.tableHeader;
							that.tableSummary = res.data.tableSummary;

							let date_range = res.data.date_range;
							let date_range_compare = res.data.date_range_compare;
							let brand = res.data.brand;
							let area = res.data.area;
							that.tableTitle_this_year = date_range;

							that.tableTitle_category = '产品销量订货额对比 - ' + brand + '品牌';
							that.table_title_product = '产品销量订货额对比 - ' + brand + '品牌';

							that.bar_chart_category_quantity.title.text = date_range + '年对比' + date_range_compare +
								'年产品销量（桶）- ' + area + '地区 - ' + brand + '品牌';
							that.bar_chart_category_quantity.xAxis[0].data = res.data.bar_category_xAxis_data;
							that.bar_chart_category_quantity.series = res.data.bar_chart_category_quantity_series_data;

							that.bar_chart_category_amount.title.text = date_range + '年对比' + date_range_compare +
								'年产品订货额（元）- ' + area + '地区 - ' + brand + '品牌';
							that.bar_chart_category_amount.xAxis[0].data = res.data.bar_category_xAxis_data;
							that.bar_chart_category_amount.series = res.data.bar_chart_category_amount_series_data;

							that.bar_chart_product_quantity.title.text = date_range + '年对比' + date_range_compare +
								'年产品销量（桶）- ' + area + '地区 - ' + brand + '品牌';
							that.bar_chart_product_quantity.xAxis[0].data = res.data.bar_product_xAxis_data;
							that.bar_chart_product_quantity.series = res.data.bar_chart_product_quantity_series_data;

							that.bar_chart_product_amount.title.text = date_range + '年对比' + date_range_compare +
								'年产品订货额（元）- ' + area + '地区 - ' + brand + '品牌';
							that.bar_chart_product_amount.xAxis[0].data = res.data.bar_product_xAxis_data;
							that.bar_chart_product_amount.series = res.data.bar_chart_product_amount_series_data;

						}
					})
					.catch(function(error) {
						console.log(error);
						that.loading = false;
					});
			},

			//提交导出数据
			submitExportData(formName) {
				// console.log(this.searchData)
				let that = this;
				this.$refs[formName].validate((valid) => {
					if (valid) {
						that.exportTableData(that.searchData, '按年份对比的产品销售数据');

					} else {
						return false;
					}
				});
			},
			exportTableData(queryData, filename) {
				let that = this;
				that.loading = true;
				//添加表单数据
				let fd = new FormData();
				let formData = queryData;
				for (let key in formData) {
					fd.append(key, formData[key]);
				};
				let fileName = filename + Date.now();

				this.$axios({
						url: '/apis/export_yearly_product_compare/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						},
						responseType: 'blob'
					})
					.then(function(res) {
						const _res = res.data;
						let blob = new Blob([_res], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
						});
						let objectUrl = window.URL.createObjectURL(blob);
						let a = document.createElement('a');
						a.href = objectUrl;
						a.download = fileName.replace('.', '');
						a.dispatchEvent(new MouseEvent('click', {
							bubbles: true,
							cancelable: true,
							view: window
						}));
						that.loading = false;
						window.URL.revokeObjectURL(blob);
					})
					.catch(function(err) {
						that.loading = false;
						console.log(err);
					});
			}
		}
	};
</script>

<style scoped>
	.title-text {
		color: #00a6dd;
		font-size: 18px;
		font-weight: 800;
	}

	.item {
		margin-bottom: 18px;
	}

	.clearfix:before,
	.clearfix:after {
		display: table;
		content: '';
	}

	.clearfix:after {
		clear: both;
	}

	.box-card {
		width: auto;
		margin: 0;
	}

	.echarts {
		width: 100%;
		height: 100%;
	}

	.plate-card {
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		height: 350px;
	}

	.margin-bottom-sm {
		margin-bottom: 10px;
	}
</style>