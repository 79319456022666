import { render, staticRenderFns } from "./monthly_area_summary.vue?vue&type=template&id=b8ea71fa&scoped=true"
import script from "./monthly_area_summary.vue?vue&type=script&lang=js"
export * from "./monthly_area_summary.vue?vue&type=script&lang=js"
import style0 from "./monthly_area_summary.vue?vue&type=style&index=0&id=b8ea71fa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8ea71fa",
  null
  
)

export default component.exports