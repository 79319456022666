<template>
	<div v-loading="loading" element-loading-text="下载中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row class="margin-bottom-sm">
			<el-card>
				<el-form ref="formSearch" :model="searchData" label-width="80px" size="mini" :inline="true">
					<el-form-item prop="date_range">
						<el-date-picker v-model="searchData.date_range" type="year" align="right" value-format="yyyy"
							size="mini" placeholder="请选择年份" />
					</el-form-item>
					<el-form-item prop="area">
						<el-select v-model="searchData.area" placeholder="请选择地区" multiple collapse-tags>
							<el-option v-for="item in areaOptions" :key="item.index" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitFormSearchData()">查看报表</el-button>
						<el-button @click="resetSearchForm('formSearch')">重置</el-button>
						<el-button type="success" @click="exportTableData(searchData,'年度销售目标完成情况')">导出</el-button>
					</el-form-item>
					<el-divider direction="vertical"></el-divider>
					<el-form-item>
						<el-checkbox v-model="quantity_checked">销量</el-checkbox>
						<el-checkbox v-model="amount_checked">订货额</el-checkbox>
					</el-form-item>
					<el-divider direction="vertical"></el-divider>
					<el-form-item label="图表风格">
						<el-select v-model="echartTheme">
							<el-option v-for="theme in themes" :key="theme.value" :label="theme.label"
								:value="theme.value"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</el-card>
		</el-row>

		<el-row style="margin-top:10px;">
			<el-card>
				<el-tabs v-model="tableActiveName">
					<el-tab-pane label="全年进度" name="table_year">
						<el-table :data="tableDataYearly" style="width: 100%; " show-summary
							:summary-method="getYearlySummary" height="600">
							<el-table-column label="地区" prop="province" sortable width="100" />
							<el-table-column :label="tableTitle">
								<el-table-column label="销售目标">
									<el-table-column label="销量(桶)" prop="target_quantity" sortable>
									</el-table-column>
									<el-table-column label="订货额(元)" prop="target_amount" sortable>
									</el-table-column>
								</el-table-column>
								<el-table-column label="实际销售">
									<el-table-column label="销量(桶)" prop="progress_quantity" sortable>
									</el-table-column>
									<el-table-column label="订货额(元)" prop="progress_amount" sortable>
									</el-table-column>
								</el-table-column>
								<el-table-column label="完成率">
									<el-table-column label="销量(%)" prop="percent_quantity" sortable>
										<template slot-scope="scope">
											{{ scope.row.percent_quantity }}%
										</template>
									</el-table-column>
									<el-table-column label="订货额(%)" prop="percent_amount" sortable>
										<template slot-scope="scope">
											{{ scope.row.percent_amount }}%
										</template>
									</el-table-column>
								</el-table-column>
								<el-table-column label="差额(目标-实际)">
									<el-table-column label="销量(桶)" prop="dvalue_quantity" sortable>
									</el-table-column>
									<el-table-column label="订货额(元)" prop="dvalue_amount" sortable>
									</el-table-column>
								</el-table-column>
							</el-table-column>
						</el-table>
					</el-tab-pane>
					<el-tab-pane label="按月" name="table_month">
						<el-table :data="tableDataMonthly" style="width: 100%; " show-summary
							:summary-method="getMonthlySummary" height="600">
							<el-table-column fixed label="地区" prop="province" sortable width="100">
							</el-table-column>
							<el-table-column :label="tableTitle">
								<el-table-column :label="item.label" v-for="(item, index) in tableHeaderMonthly" :key="index">
									<el-table-column label="销量(%)" :prop="item.prop_quantity" sortable width="120"
										v-if="quantity_checked">
									</el-table-column>
									<el-table-column label="订货额(%)" :prop="item.prop_amount" sortable width="120"
										v-if="amount_checked">
									</el-table-column>
								</el-table-column>
							</el-table-column>
						</el-table>
					</el-tab-pane>
				</el-tabs>
			</el-card>
		</el-row>

		<el-row style="margin-top: 10px;" :gutter="15">
			<el-col :sm="24" :xs="24">
				<el-card>
					<el-tabs v-model="barChartActiveName">
						<el-tab-pane label="全年" name="bar_brand">
							<div class="plate-card"><v-chart :options="bar_progress_yearly" autoresize
									:theme="echartTheme">/></v-chart>
							</div>

						</el-tab-pane>

						<el-tab-pane label="按月" name="bar_area">
							<el-row :gutter="15">
								<el-col :sm="24" :xs="24">
									<div class="plate-card"><v-chart :options="bar_progress_monthly_quantity" autoresize
											:theme="echartTheme">/></v-chart>
									</div>
								</el-col>
								<el-col :sm="24" :xs="24">
									<div class="plate-card"><v-chart :options="bar_progress_monthly_amount" autoresize
											:theme="echartTheme">/></v-chart>
									</div>
								</el-col>
							</el-row>

						</el-tab-pane>

					</el-tabs>
				</el-card>
			</el-col>
		</el-row>

	</div>
</template>


<script>
	import ECharts from 'vue-echarts';
	export default {
		inject: ['logout'],
		data() {
			return {
				searchData: {
					area: '',
					date_range: ''
				},
				tableDataYearly: [],
				tableDataMonthly: [],
				tableHeaderMonthly: [{
					label: '月份',
					prop_quantity: '',
					prop_amount: ''
				}],
				tableYearlySummary: [],
				tableMonthlySummary: [],
				quantity_checked: true,
				amount_checked: true,
				tableTitle: '年度销售目标完成情况',
				tableActiveName: 'table_year',
				loading: false,
				echartTheme: 'light',
				themes: [{
						value: 'light',
						label: '默认'
					},
					{
						value: 'dark',
						label: '黑暗'
					},
					{
						value: 'shine',
						label: '闪亮'
					},
					{
						value: 'vintage',
						label: '复古'
					},
					{
						value: 'helianthus',
						label: '太阳花'
					},
					{
						value: 'tech-blue',
						label: '科技蓝'
					},
					{
						value: 'london',
						label: '伦敦'
					},
					{
						value: 'roma',
						label: '罗马'
					},
					{
						value: 'sakura',
						label: '樱花粉'
					},
					{
						value: 'fresh-cut',
						label: '鲜果切'
					}
					// 你可以继续添加其他主题
				],
				barChartActiveName: 'bar_brand',
				pieChartActiveName: 'pie_total',
				areaOptions: [],
				bar_progress_yearly: {},
				bar_progress_monthly_quantity: {},
				bar_progress_monthly_amount: {},
				bar_chart_quantity: {
					title: {
						text: '地区销售目标完成率(%)',
						left: 'center',
						top: 10
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						},
						formatter: function(params) {
							let tooltipText = `${params[0].axisValue}<br/>`;
							params.forEach((item) => {
								tooltipText +=
									`${item.marker}${item.seriesName}: ${item.value}% <br/>`;
							});
							return tooltipText;
						}

					},
					toolbox: {
						show: true,
						orient: 'horizontal',
						left: 'right',
						top: 'top',
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: true
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					legend: {
						bottom: 5,
						left: 'center',
						orient: 'horizontal',
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '10%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						data: []
					}],
					yAxis: [{
						type: 'value'
					}],
					// dataZoom: [{
					// 	type: 'slider',
					// 	xAxisIndex: 0,
					// 	start: 10,
					// 	end: 100
					// }],
					series: [{
							name: '销量完成率',
							type: 'bar',
							// stack: 'Ad',
							emphasis: {
								focus: 'series'
							},
							data: [],
							markLine: {
								data: [{
									name: 'sdfadfadfdsaf',
									yAxis: '100',
								}]
							}
						},
						{
							name: '订货额完成率',
							type: 'bar',
							// stack: 'Ad',
							emphasis: {
								focus: 'series'
							},
							data: []
						},

					]
				},
				line_chart_monthly: {
					title: {
						text: '地区订货额完成率（元）',
						left: 'center',
						top: 20
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'line'
						},
						formatter: function(params) {
							let tooltipText = `${params[0].axisValue}<br/>`;
							params.sort((a, b) => b.value - a.value);
							params.forEach((item, index) => {
								tooltipText +=
									` ${item.marker}${item.seriesName}: ${item.value}%`;
								if ((index + 1) % 3 === 0 && index !== params.length - 1) {
									tooltipText += `<br/>`; 
								}
							});
							return tooltipText;
						}
					},
					toolbox: {
						show: true,
						orient: 'vertical',
						left: 'right',
						top: 'center',
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: true
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					legend: {
						bottom: 10,
						left: 'center',
						orient: 'horizontal',
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '15%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						data: []
					}],
					yAxis: [{
						type: 'value'
					}],
					series: [{
						name: '地区',
						type: 'line',
						emphasis: {
							focus: 'series'
						},
						data: []
					}, ]
				},
			};
		},
		mounted() {
			// 获取数据
			this.getAreaOptions();
		},
		created() {

			// 深拷贝数据以取消双向绑定
			this.bar_progress_yearly = {
				...JSON.parse(JSON.stringify(this.bar_chart_quantity)), // 使用浅拷贝保留大部分属性
				tooltip: { // 手动添加 tooltip 以保留 formatter 函数
					...this.bar_chart_quantity.tooltip,
					formatter: this.bar_chart_quantity.tooltip.formatter
				}
			};

			this.bar_progress_monthly_quantity = {
				...JSON.parse(JSON.stringify(this.line_chart_monthly)), // 使用浅拷贝保留大部分属性
				tooltip: { // 手动添加 tooltip 以保留 formatter 函数
					...this.line_chart_monthly.tooltip,
					formatter: this.line_chart_monthly.tooltip.formatter
				}
			};

			this.bar_progress_monthly_amount = {
				...JSON.parse(JSON.stringify(this.line_chart_monthly)), // 使用浅拷贝保留大部分属性
				tooltip: { // 手动添加 tooltip 以保留 formatter 函数
					...this.line_chart_monthly.tooltip,
					formatter: this.line_chart_monthly.tooltip.formatter
				}
			};

		},
		methods: {
			//重置搜索表单
			resetSearchForm(formName) {
				let that = this;
				this.$refs[formName].resetFields();
				this.$refs[formName].clearValidate();
			},
			//提交搜索
			submitFormSearchData() {
				// console.log(this.searchData)
				this.getTableAndChartData(this.searchData);
			},
			// 获取总和数据
			getYearlySummary() {
				return this.tableYearlySummary;
			},
			getMonthlySummary() {
				return this.tableMonthlySummary;
			},
			// 获取地区列表
			getAreaOptions() {
				let that = this;
				this.$axios
					.get('/apis/get_area_list/')
					.then(function(response) {
						if (response.data.code == '401') {
							that.logout();
						} else {
							that.areaOptions = response.data;
						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},

			//获取表格及图表数据
			getTableAndChartData(queryData) {
				let that = this;
				that.loading = true;
				//添加表单数据
				let fd = new FormData();
				let formData = queryData;
				for (let key in formData) {
					fd.append(key, formData[key]);
				}
				if (queryData.date_range == null) {
					fd.set('date_range', '');
				}
				this.$axios({
						url: '/apis/get_yearly_sales_progress/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then(function(res) {
						// console.log(res)
						that.loading = false;
						if (res.data.code == '401') {
							that.logout();
						} else {

							that.tableDataYearly = res.data.tableDataYearly;
							that.tableDataMonthly = res.data.tableDataMonthly;
							that.tableHeaderMonthly = res.data.tableHeaderMonthly;
							that.tableTitle = res.data.table_title + '销售目标完成情况'
							that.tableYearlySummary = res.data.tableYearlySummary;
							that.tableMonthlySummary = res.data.tableMonthlySummary;


							that.bar_progress_yearly.title.text = res.data.table_title + '-销售目标完成率';
							that.bar_progress_yearly.xAxis[0].data = res.data.bar_progress_yearly_xAxis_data;
							that.bar_progress_yearly.series[0].data = res.data.bar_progress_yearly_quantity_series;
							that.bar_progress_yearly.series[1].data = res.data.bar_progress_yearly_amount_series;


							that.bar_progress_monthly_quantity.title.text = res.data.table_title + '月销量完成率';
							that.bar_progress_monthly_quantity.series = res.data.bar_progress_monthly_quantity_series;
							that.bar_progress_monthly_quantity.xAxis[0].data = res.data.bar_progress_monthly_xAxis_data;

							that.bar_progress_monthly_amount.title.text = res.data.table_title + '月订货额完成率';
							that.bar_progress_monthly_amount.series = res.data.bar_progress_monthly_amount_series;
							that.bar_progress_monthly_amount.xAxis[0].data = res.data.bar_progress_monthly_xAxis_data;
						}
					})
					.catch(function(error) {
						console.log(error);
						that.loading = false;
					});
			},

			//下载数据
			exportTableData(queryData, filename) {
				let that = this;
				that.loading = true;
				//添加表单数据

				let fd = new FormData();
				let formData = queryData;
				for (let key in formData) {
					fd.append(key, formData[key]);
				}
				if (queryData.date_range == null) {
					fd.set('date_range', '');
				}

				let fileName = filename + Date.now();

				this.$axios({
						url: '/apis/export_yearly_sales_progress/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						},
						responseType: 'blob'
					})
					.then(function(res) {
						const _res = res.data;
						let blob = new Blob([_res], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
						});
						let objectUrl = window.URL.createObjectURL(blob);
						let a = document.createElement('a');
						a.href = objectUrl;
						a.download = fileName.replace('.', '');
						a.dispatchEvent(new MouseEvent('click', {
							bubbles: true,
							cancelable: true,
							view: window
						}));
						that.loading = false;
						window.URL.revokeObjectURL(blob);
					})
					.catch(function(err) {
						that.loading = false;
						console.log(err);
					});
			}
		}
	};
</script>

<style scoped>
	.title-text {
		color: #00a6dd;
		font-size: 18px;
		font-weight: 800;
	}

	.item {
		margin-bottom: 18px;
	}

	.clearfix:before,
	.clearfix:after {
		display: table;
		content: '';
	}

	.clearfix:after {
		clear: both;
	}

	.box-card {
		width: auto;
		margin: 0;
	}

	.echarts {
		width: 100%;
		height: 100%;
	}

	.plate-card {
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		height: 450px;
	}

	.margin-bottom-sm {
		margin-bottom: 10px;
	}
</style>