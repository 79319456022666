<template>
	<div>
		<el-row :gutter="10">
			<el-col :xs="24" :sm="24">
				<el-card>
					<el-tabs v-model="activeName" @tab-click="handleClick" tab-position="top">
						<el-tab-pane name="managedata">
							<span slot="label">
								<i class="el-icon-s-order"></i>
								产品信息管理
							</span>
							<div>
								<el-input v-model="search" placeholder="搜索" @input="fetchData"
									style="margin-bottom: 20px; margin-right:20px; width: 300px;"
									size="mini"></el-input>
								<el-button type="primary" @click="openDialog()" size="mini">添加产品</el-button>
								<el-button type="danger" @click="deleteSelected" :disabled="!multipleSelection.length"
									size="mini">批量删除</el-button>

								<el-table ref="multipleTable" :data="productInfo" stripe style="width: 100%;"
									@sort-change="handleSortChange" @selection-change="handleSelectionChange"
									v-loading="loading">
									<el-table-column type="selection" width="55"></el-table-column>
									<el-table-column prop="name" label="产品" sortable></el-table-column>
									<el-table-column prop="specification" label="规格" sortable></el-table-column>
									<el-table-column prop="brand" label="品牌" sortable></el-table-column>
									<el-table-column prop="category" label="分类" sortable></el-table-column>
									<el-table-column label="操作">
										<template slot-scope="scope">
											<el-button-group>
												<el-button size="mini" icon="el-icon-edit"
													@click="openDialog(scope.row)">改</el-button>
												<el-button type="danger" size="mini" icon="el-icon-delete"
													@click="deleteproductInfo(scope.row)">删</el-button>
											</el-button-group>
										</template>
									</el-table-column>
								</el-table>

								<el-pagination v-if="total > 0" @size-change="handleSizeChange"
									@current-change="handleCurrentChange" :current-page="currentPage"
									:page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
									layout="total, sizes, prev, pager, next, jumper" :total="total"
									class="paginator hidden-sm-and-down">
								</el-pagination>

								<el-dialog :title="dialogTitle" :visible.sync="dialogVisible"
									:close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
									<el-form :model="formData" :rules="rules" ref="form" label-position="left" label-width="80px">
										<el-form-item label="产品名称" prop="name" >
											<el-input v-model="formData.name" style="max-width: 210px;" />
										</el-form-item>
										<el-form-item label="规格" prop="specification">
											<el-select v-model="formData.specification" placeholder="选择规格" filterable>
												<el-option v-for="item in specficationOption" :key="item.value"
													:label="item.lable" :value="item.value"></el-option>
											</el-select>
										</el-form-item>
										<el-form-item label="品牌" prop="brand">
											<el-select v-model="formData.brand" placeholder="选择品牌" filterable>
												<el-option v-for="item in brandOption" :key="item.value"
													:label="item.lable" :value="item.value"></el-option>
											</el-select>
										</el-form-item>
										<el-form-item label="品类" prop="category">
											<el-select v-model="formData.category" placeholder="选择品类" filterable>
												<el-option v-for="item in categoryOption" :key="item.value"
													:label="item.lable" :value="item.value"></el-option>
											</el-select>
										</el-form-item>

									</el-form>
									<div slot="footer" class="dialog-footer">
										<el-button @click="dialogVisible = false">取消</el-button>
										<el-button type="primary" @click="saveData">保存</el-button>
									</div>
								</el-dialog>

								<!-- 这里可以添加经销商数据管理的内容 -->
							</div>
						</el-tab-pane>
						<el-tab-pane name="uploadfile">
							<span slot="label">
								<i class="el-icon-s-platform"></i>
								产品数据导入
							</span>
							<div>
								<el-row :gutter="10">
									<el-col :sm="8" :xs="24">
										<el-form :disabled="isUploading">
											<el-form-item label="选择文件" :required="true">
												<el-upload action="none" class="upload-demo" ref="upload"
													:auto-upload="false" :on-change="handleFileChange"
													:on-remove="handleFileRemove" :multiple="false" accept=".xlsx, .xls"
													:file-list="fileList">
													<el-button size="small" type="primary">选择文件</el-button>
													<div slot="tip" class="el-upload__tip">只能上传 .xlsx 和 .xls 文件</div>
												</el-upload>
											</el-form-item>
										</el-form>
										<el-button :disabled="isUploading" @click="submitUpload">导入</el-button>

									</el-col>
									<el-col :sm="16" :xs="24">
										<el-table :data="importResultTableData" border style="width: 100%"
											max-height="500">
											<el-table-column label="验证结果">
												<el-table-column prop="row" label="行数" width="180">
												</el-table-column>
												<el-table-column prop="message" label="问题">
												</el-table-column>
											</el-table-column>
										</el-table>
									</el-col>
								</el-row>
							</div>
						</el-tab-pane>
					</el-tabs>
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import axios from 'axios';
	export default {
		inject: ['reload', 'logout'],
		data() {
			return {
				activeName: 'managedata', // 设置默认显示的 tab
				selectedFile: null, // 用于存储用户选择的文件
				isUploading: false, // 用于在上传时禁用按钮
				fileList: [],
				importResultTableData: [{
					row: '',
					message: ''
				}],
				productInfo: [],
				products: [],
				search: '',
				total: 100,
				currentPage: 1,
				pageSize: 10,
				sortField: '',
				sortOrder: '',
				loading: false,
				multipleSelection: [],
				dialogVisible: false,
				dialogTitle: '添加产品销量',
				formData: {
					id: null,
					name: '',
					specification: '',
					brand: '',
					category: '',
				},
				brandOption: [{
						"item": "进口鲁沃夫",
						"value": "进口鲁沃夫"
					},
					{
						"item": "国产鲁沃夫",
						"value": "国产鲁沃夫"
					},
				],
				categoryOption: [{
						"item": "多酶清洗剂",
						"value": "多酶清洗剂"
					},
					{
						"item": "酸性清洗剂",
						"value": "酸性清洗剂"
					},
					{
						"item": "碱性清洗剂",
						"value": "碱性清洗剂"
					},
					{
						"item": "润滑剂",
						"value": "润滑剂"
					},
					{
						"item": "预处理",
						"value": "预处理"
					},
					{
						"item": "其他类",
						"value": "其他类"
					},
				],
				specficationOption: [{
						"item": "4L",
						"value": "4L"
					},
					{
						"item": "5L",
						"value": "5L"
					},
					{
						"item": "10L",
						"value": "10L"
					},
					{
						"item": "2L",
						"value": "2L"
					},
					{
						"item": "20L",
						"value": "20L"
					},
					{
						"item": "60L",
						"value": "60L"
					},
					{
						"item": "2.5L",
						"value": "2.5L"
					},
					{
						"item": "500ML",
						"value": "500ML"
					},
					{
						"item": "615ML",
						"value": "615ML"
					},
					{
						"item": "412ML",
						"value": "412ML"
					},
				],

				rules: {
					name: [{
						required: true,
						message: '请输入产品名称',
						trigger: 'blur'
					}],
					specification: [{
						required: true,
						message: '请选择产品规格',
						trigger: 'blur'
					}],
					brand: [{
						required: true,
						message: '请选择品牌',
						trigger: 'blur'
					}],
					category: [{
						required: true,
						message: '请选择品类',
						trigger: 'blur'
					}]
				}
			};
		},
		methods: {
			handleClick(tab) {
				// console.log('切换到 tab:', tab.name);
			},

			handleFileChange(file, fileList) {
				this.selectedFile = file.raw; // 获取原始文件对象
				this.fileList = fileList.slice(-1);
			},
			handleFileRemove(file, fileList) {
				this.selectedFile = null; // 获取原始文件对象
			},
			submitUpload() {
				let that = this;
				if (!this.selectedFile) {
					this.$message.warning('请先选择要上传的文件');
					return;
				}
				this.isUploading = true;
				const formData = new FormData();
				formData.append('file', this.selectedFile);
				that.$axios({
						url: '/apis/import_product_data/',
						method: 'post',
						data: formData,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then(function(res) {
						that.selectedFile = null;
						that.fileList = [];
						that.$refs.upload.clearFiles()
						if (res.data.code == '401') {
							that.importResultTableData = res.data.errors;
							that.$message({
								message: res.data.message,
								type: 'warning',
								onClose: function() {
									that.isUploading = false;
								}
							});
						} else if (res.data.code == '200') {
							// console.log(res.data.message)
							that.importResultTableData = res.data.message.errors;
							that.$message({
								message: '成功导入' + res.data.message.success + '条数据',
								type: 'success',
								onClose: function() {
									that.isUploading = false;
								}
							});
						}
						that.fetchData();
					})
					.catch(function(error) {
						that.selectedFile = null;
						that.fileList = [];
						that.$refs.upload.clearFiles()
						that.$message({
							message: error,
							type: 'warning',
							onClose: function() {
								that.isUploading = false;
							}
						});
						that.fetchData();
					});
			},

			//获取产品数据
			fetchData() {
				let that = this;
				this.loading = true;
				this.$axios.get('/apis/order_data/products/', {
					params: {
						search: this.search,
						ordering: this.sortOrder ?
							`${this.sortOrder === 'descending' ? '-' : ''}${this.sortField}` : '',
						page: this.currentPage,
						page_size: this.pageSize
					}
				}).then(response => {
					// console.log(response)
					if (response.data.results) {
						this.productInfo = response.data.results;
						this.total = response.data.count;
					} else {
						that.$message({
							message: response.errors,
							type: 'warning',
							onClose: function() {
								that.isUploading = false;
							}
						});
					}

				}).finally(() => {
					this.loading = false;
				});
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.fetchData();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.fetchData();
			},
			handleSortChange({
				prop,
				order
			}) {
				this.sortField = prop;
				this.sortOrder = order;
				this.fetchData();
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},

			openDialog(row = null) {
				this.dialogVisible = true;

				this.$nextTick(() => {
					this.$refs.form.clearValidate();
				});

				if (row) {
					this.dialogTitle = '修改产品';
					this.formData = {
						...row,
					};
				} else {
					this.dialogTitle = '新增产品';
					this.formData = {
						id: null,
						name: '',
						specification: '',
						brand: '',
						category: '',
					};
				}
			},
			//保存记录
			saveData() {
				let that = this;
				this.$refs.form.validate((valid) => {
					if (valid) {
						const method = this.formData.id ? 'put' : 'post';
						const url = this.formData.id ?
							`/apis/order_data/products/${this.formData.id}/` :
							'/apis/order_data/products/';
						this.$axios[method](url, this.formData)
							.then(response => {
								this.dialogVisible = false;
								this.fetchData();
							})
							.catch(error => {
								if (error.response && error.response.status === 400) {
									// console.log(error.response.data.non_field_errors)
									that.$message({
										message: error.response.data.non_field_errors.join(','),
										type: 'warning',
									});
								} else {
									that.$message({
										message: '保存失败，服务器错误',
										type: 'warning',
									});
								}
							});
					}
				});
			},
			// 单个删除
			deleteproductInfo(row) {
				this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						this.$axios.delete(`/apis/order_data/products/${row.id}/`)
							.then(() => {
								this.fetchData();
							});
					})
					.catch(() => {});
			},
			// 批量删除
			deleteSelected() {
				this.$confirm('此操作将永久批量删除选中的记录, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						const ids = this.multipleSelection.map(item => item.id);
						this.$axios.post('/apis/order_data/products/batch-delete/', {
								ids
							})
							.then(() => {
								this.fetchData();
							});
					})
					.catch(() => {});
			},

		},
		mounted() {
			this.fetchData();
		}
	};
</script>

<style scoped>
	.upload-demo .el-upload {
		display: inline-block;
	}
</style>